<template>
  <div
    class="dot-links-resources px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-3/4 mb-6 md:pl-2 mx-auto text-center"></div>
      <div class="w-full md:w-3/4 mb-6 md:pl-2 mx-auto text-center">
        <TitleComponent title="DOT Links and Resources" size="large" />
        <Paragraph
          ><h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            <a
              title="DOT Office of HAZMAT Safety"
              href="http://www.phmsa.dot.gov/hazmat"
              target="_new"
              >DOT Office of HAZMAT Safety</a
            >
          </h2>
          <p>
            Click on the above title to reach the homepage of the Pipeline and
            Hazardous Materials Safety Administration (PHMSA) Office of
            Hazardous Materials Safety (OHM) for the Department of
            Transportation (DOT). The OHM formulates, issues and revises
            Hazardous Materials Regulations (HMR) under the Federal Hazardous
            Materials Transportation Law. The HMR cover hazardous materials
            definitions and classifications, hazard communications, shipper and
            carrier operations, training and security requirements, and
            packaging and container specifications.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            <a
              title="Office of Hazardous Materials Special Permits and Approvals"
              href="http://www.phmsa.dot.gov/hazmat/regs/sp-a"
              target="_new"
              >Office of Hazardous Materials Special Permits and Approvals</a
            >
          </h2>
          <p>
            Click on the above title to reach the homepage of the Pipeline and
            Hazardous Materials Safety Administration (PHMSA) Office of
            Hazardous Materials Special Permits and Approvals (OHMSPA) for the
            Department of Transportation (DOT). Primary responsibility for the
            issuance of DOT Special Permits (previously known as DOT Exemptions)
            and Approvals to the Hazardous Materials Regulations (HMR).
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            <a
              title="Contact OHMSPA"
              href="https://www.phmsa.dot.gov/standards-rulemaking/hazmat/hazardous-materials-information-center"
              target="_new"
              >Contact OHMSPA</a
            >
          </h2>
          <p>
            Do you need to contact the Office of Hazardous Materials Special
            Permits and Approvals (OHMSPA)? Find the address and phone numbers
            for contacting the divisions of Special Permits or Approvals by
            clicking the above title.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            <a
              title="Organization Chart with Contact Information"
              href="https://www.phmsa.dot.gov/about-phmsa/offices/office-hazardous-materials-safety-organization-chart"
              target="_new"
              >Organization Chart with Contact Information</a
            >
          </h2>
          <p>
            Need a quick-reference chart that shows the organizational structure
            of the Office of Hazardous Materials Safety with contact
            information? Click the title above.
          </p>
          <h2
            data-fontsize="18"
            data-lineheight="27px"
            class="fusion-responsive-typography-calculated"
            style="--fontSize:18; line-height: 1.5; --minFontSize:18;"
          >
            <a
              title="Browse or Search List of Approved Special Permits"
              href="https://www7.phmsa.dot.gov/approvals-and-permits/hazmat/special-permits-search"
              target="_new"
              >Browse or Search List of Approved Special Permits</a
            >
          </h2>
          <p>
            Click the above title to browse or search through the list of
            approved special permits on file with the DOT.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "DOT Resources",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content: "Important links to DOT information."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.dot-links-resources {
  h2 {
    font-size: 18px;
    margin-bottom: 1.1em;
  }
  a {
    color: #aa222c;
  }
}
</style>
